import 'lib-flexible/flexible.js';
import { Toast } from 'vant';
import 'vant/lib/index.css';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router/index';
import '@/assets/font/font.css';

const app = createApp(App)
app.use(router)
app.use(Toast)
app.mount('#app')
