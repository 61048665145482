import request from "@/api/index.js";

export function getXianHuoDetail(xid) {
  return new Promise(resove => {
    request({
      url: `/merchandise/` + xid,
      method: "get",
    }).then(res => {
      resove(res.data);
    });
  });
}

export function getXianHuoHotList() {
    return new Promise(resove => {
      request({
        url: `/merchandise/hotList`,
        method: "get",
      }).then(res => {
        resove(res.data);
      });
    });
  }