import request from "@/api/index.js";
export function wechatSign(url, infoData) {
  return new Promise(resove => {
    request({
      url: `/wechat/sign?url=` + url,
      method: "get",
    }).then(res => {
      resove(res.data);
      var map = res.data;
      // alert(JSON.stringify(map))
      wx.config({
        debug: false,
        appId: map["appId"], // 必填，公众号的唯一标识
        timestamp: map["timestamp"], // 必填，生成签名的时间戳
        nonceStr: map["nonceStr"], // 必填，生成签名的随机串
        signature: map["signature"], // 必填，签名
        jsApiList: [
          "onMenuShareAppMessage",
          "chooseWXPay",
          "updateAppMessageShareData"
        ],
        openTagList: ["wx-open-launch-app"]
      });
      wx.ready(function () {
        if (infoData != null) {
          wx.updateAppMessageShareData({
            title: infoData.title, // 分享标题
            desc: infoData.desc, // 分享描述
            link: infoData.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
            imgUrl: 'https://img.liangce.cc/app/app_share_icon.png', // 分享图标
            success: function () {
              // 设置成功
            }
          });
        }
        var btn = document.getElementById('launch-btn');
        if(btn){
          btn.addEventListener('launch', function (e) {
            console.log('success');
          });
          //https://a.app.qq.com/o/simple.jsp?pkgname=com.liangcang.lcapp
          btn.addEventListener('error', function (e) {
            window.location.href =
            "https://we.xyzzzzz.com";
          });
        }
        resove(true);
      });
      wx.error(function (res) {
        resove(false);
      });
    });
  });
}