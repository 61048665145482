import request from "./index";

export function getcode(phone) {
  return new Promise(resove => {
    request({
      url: `/user/send/phone`,
      method: "post",
      data:{
        "phone":phone
      }
    }).then(res => {
      resove(res.data);
    });
  });

}


export function login(phone,code,invite) {
  return new Promise(resove => {
    request({
      url: `/user/setinvite`,
      method: "post",
      data:{
        'phone':phone,
        'code':code,
        'invite':invite
      }
    }).then(res => {
      resove(res.data);
    });
  });

}

export function getLoginInfo(lcID) {
  return new Promise(resove => {
    request({
      url: `/user/get/login2`,
      method: "post",
      data:{
        'uid':lcID,
      }
    }).then(res => {
      resove(res.data);
    });
  });

}

export function getCarList(page,size,startDay,endDay,factoryId) {
  let map = {
    'page':page,
    'size':size,
    'endDay':endDay,
    'startDay':startDay,
  };
  if(factoryId!=null&&factoryId!=undefined){
    map['factoryId']=factoryId
  }
  return new Promise(resove => {
    request({
      url: `/car/findList`,
      method: "post",
      data:map
    }).then(res => {
      resove(res.data);
    });
  });

}

export function getShopPrice(trendType,type) {
  return new Promise(resove => {
    request({
      url: `/factoryprice/selectAveragePrice`,
      method: "post",
      data:{
        'trendType':trendType,
        'type':type
      }
    }).then(res => {
      resove(res.data);
    });
  });

}

export function getShopPriceList(page,size,time,type,unit) {
  return new Promise(resove => {
    request({
      url: `/factoryprice/selectFactoryPrice`,
      method: "post",
      data:{
        'page':page,
        'size':size,
        'time':time,
        'type':type,
        'unit':unit
      }
    }).then(res => {
      resove(res.data);
    });
  });

}

export function getMerchandiseTypes() {
  return new Promise(resove => {
    request({
      url: `/merchandiseType/merchandiseTypes`,
      method: "get",
    }).then(res => {
      resove(res.data);
    });
  });

}

export function getFactoryPriceTypes() {
  return new Promise(resove => {
    request({
      url: `/factoryprice/factoryPriceType`,
      method: "get",
    }).then(res => {
      resove(res.data);
    });
  });

}

export function getShopList() {
  return new Promise(resove => {
    request({
      url: `/factory/isCarFactory`,
      method: "get",
    }).then(res => {
      resove(res.data);
    });
  });

}

export function getImportList(page,size,createTime) {
  return new Promise(resove => {
    request({
      url: `/import/stats/list`,
      method: "post",
      data:{
        'page':page,
        'size':size,
        'createTime':createTime,
      }
    }).then(res => {
      resove(res.data);
    });
  });

}



