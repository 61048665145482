import { createRouter, createWebHashHistory } from "vue-router";

// 引入
import LcvueCarInfo from "@/components/discover/car_info.vue";
import LcvueShopPrice from "@/components/discover/shop_price.vue";
import HelloWorldVue from "@/components/HelloWorld.vue";
import LcvueInviteSuccess from "@/components/share_html/invite_success.vue";
import LcvueInviteUser from "@/components/share_html/invite_user.vue";
import LcvueShareNormal from "@/components/share_html/share_normal.vue";
import LcvueUserPrivacy from "@/components/xieyi/user_ privacy.vue";
import LcvueUserProtocol from "@/components/xieyi/user_protocol.vue";
import LcvueJinkouList from "@/components/discover/jinkou_list.vue";
import LcvueZixun from "@/components/zixun/zixun.vue";
import LcvueVideo from "@/components/video/video.vue";
import LcvueXianhuo from "@/components/xianhuo/xianhuo.vue";
import LcvueTest from "@/components/test/test.vue";
import LcvueUserCancle from '@/components/xieyi/user_cancle.vue'

// 路由信息
let routes = [
  {
    path: "/",
    name: 'shareNormal',
    component: LcvueShareNormal,
  },
  {
    path: "/share",
    name: 'share',
    component: LcvueShareNormal,
  },
  {
    path: "/redirect",
    redirect:'/common'
  },
  {
    path: "/common",
    name: 'common',
    component: LcvueInviteSuccess,
  },
  {
    path: "/hello",
    name: 'hello',
    component: HelloWorldVue,
  },
  {
    path: "/invite",
    name: 'invite',
    component: LcvueInviteUser,
  },
  {
    path: "/userProtocol",
    name: 'userProtocol',
    component: LcvueUserProtocol,
  },
  {
    path: "/userPrivacy",
    name: 'userPrivacy',
    component: LcvueUserPrivacy,
  },
  {
    path: "/userCancle",
    name: 'userCancle',
    component: LcvueUserCancle,
  },
  {
    path: "/carInfo",
    name: 'carInfo',
    component: LcvueCarInfo,
  },
  {
    path:"/shopPrice",
    name:"shopPrice",
    component:LcvueShopPrice
  },
  {
    path:"/jinKou",
    name:"jinKou",
    component:LcvueJinkouList
  },
  {
    path:"/zixun",
    name:"zixun",
    component:LcvueZixun
  },
  {
    path:"/test",
    name:"test",
    component:LcvueTest
  },
  {
    path:"/video",
    name:"video",
    component:LcvueVideo
  },
  {
    path:"/xianhuo",
    name:"xianhuo",
    component:LcvueXianhuo
  }
];

// 路由器
const router = createRouter({
  history:createWebHashHistory(),
  routes,
});

export default router;


