<template>
  <div class="xieyi">
    <h2>用户注销协议</h2>
    <h4>您在申请注销流程中点击同意前，应当认真阅读《帐号注销协议》 (以下简称“本协议”)。特别提醒您，当您成功提交注销申请后，即表示您已充分阅读、理解并接受本协议的全部内容。阅读本协议的过程中，如果您不同意相关任何条款，请您立即停止帐号注销程序。</h4>
    <h3>1、您应确保您有权决定该帐号的注销事宜，不侵犯任何第三方的合法权益，如因此引发任何争议.由您自行承担。</h3>
    <h3>2、您理解并同意，账号注销后我们无法协助您重新恢复前述服务。请您在申请注销前自行备份您欲保留的本帐号信息和数据。</h3>
    <h3>3、帐号注销后，已绑定的手机号、邮箱、第三方关联信息将会被解除绑定。</h3>
    <h3>4、注销帐号后，您将无法再使用本帐号，即时生效也将无法找回您帐号中及与帐号相关的任何内容或信息，包括但不限于:</h3>
    <h4>(1)您将无法继续使用该帐号进行登录</h4>
    <h4>(2)您帐号的个人资料和历史信息 (包含昵称头像、消息记录等) 都将无法找回</h4>
    <h4>(3) 您理解并同意，注销帐号后，您曾获得的会员、订单信息、发布信息及其他虚拟财产等将视为您自愿、主动放弃，无法继续使用，由此引起一切纠纷由您自行处理，我们不承担任何责任</h4>
  </div>
</template>

<script>
export default {
  name: 'LcvueUserCancle',

  data() {
    return {}
  },

  mounted() {},

  methods: {}
}
</script>

<style lang="scss" scoped>
.xieyi {
  text-align: left;
  padding: 40px;
}
</style>