<template>
  <div class="content">
    <div class="top-bar">
      <img class="logo" src="@/assets/icon.png" alt="icon" />
      <div class="info">打开粮策APP，了解市场行情</div>
      <div v-if="isWX">
        <wx-open-launch-app
          id="launch-btn"
          appid="wx66c7689c38662fd9"
          :extinfo="extinfo"
        >
          <component :is="'script'" type="text/wxtag-template">
            <div
              style="
                width: 99px;
                height: 55px;
                background: #ff8021;
                color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
              "
              class="btn"
            >
              立即打开
            </div>
          </component>
        </wx-open-launch-app>
      </div>
      <div v-else @click="openApp" class="open-btn">立即打开</div>
    </div>
    <div class="video-info">
      <div class="video">
        <video
          class="video-js vjs-default-skin vjs-big-play-centered"
          id="myVideo"
        >
          <!-- <source
            :src="video.videoUrl"
          /> -->
        </video>
      </div>
      <div class="video-cover" @click="playVideo">
        <div class="right">
          <img class="head" :src="video.img" alt="" />
          <img class="like" src="@/assets/video/comment.png" alt="" />
          <div class="text">{{video.comment}}</div>
          <img class="like" src="@/assets/video/xinxing.png" alt="" />
          <div class="text">{{video.likeCounts}}</div>
          <img class="like" src="@/assets/icon.png" alt="" />
          <div @click="openApp" class="text">打开粮策</div>
        </div>
        <div class="left-bottom">
          <div class="bottom-title">{{video.name}}</div>
          <div class="bottom-desc">{{video.videoDesc}}</div>
          <div @click="openApp" class="bottom-btn">打开粮策，看更多精彩视频</div>
        </div>
      </div>
    </div>
    <div class="tj-video">
      <div class="tj-hx"></div>
      <div class="tj-text">推荐视频</div>
      <div class="tj-hx"></div>
    </div>
    <div class="xianhuo-list">
      <div
        v-for="(item, index) in video.appVideoFineVoList"
        v-bind:key="index"
        class="xianhuo-item"
        @click="openApp"
      >
        <div class="xianhuo-img">
          <img :src="item.coverPath" alt="" />
        </div>
        <div class="xianhuo-name">{{item.videoDesc}}</div>
        <div class="xianhuo-address-year">
          <div class="bottom-item">
            <img class="head" src="@/assets/video/video_player.png" alt="" />
          <div class="address">{{item.playCount}}</div>
          </div>
          <div class="bottom-item right">
            <img class="head" src="@/assets/video/xinxing.png" alt="" />
          <div class="address">{{item.likeCounts}}</div>
          </div>
        </div>
      </div>
    </div>
    <div @click="openApp" class="bottom">
      打开粮策看该作者更多视频<span class="free">都免费</span>
    </div>
  </div>
</template>

<script>
import {getVideoDetail} from "@/api/video/video"
import Video from "video.js";
import "video.js/dist/video-js.css";
import videojs from "video.js";
import { wechatSign } from "@/api/wechat/wechat"

export default {
  data() {
    return {
      myPlayer: null,
      video:{},
       shareId:'',
        shareTitle:'粮策-买卖存粮有策略',
        shareDesc:'粮食生意不好做，看不透市场，就来粮策！为您的粮食生意保驾护航！',
        isWX:false,
        extinfo:''
    };
  },
  mounted() {
    var ua = navigator.userAgent.toLowerCase(); //判断是否是微信浏览器
    this.isWX = ua.indexOf("micromessenger") != -1; //判断是否     
    
    this.shareId = this.$route.query.shareId
    if(this.isWX){
      this.wxSign()
    }
    this.getDetail();
  },
  methods: {
    wxSign(){
      let info = {
        'title':this.shareTitle,
        'desc':this.shareDesc,
        'link':window.location.href,
      }
      wechatSign(window.location.href.split("#")[0],info)
    },
    async getDetail(){
      let res = await getVideoDetail(this.shareId)
      if(res.code == 200){
        this.video = res.data;
        this.extinfo = 'lcapp://app?shareType=1&shareId=' + this.shareId;
        this.initVideo();
      }
    },
    openApp() {
      window.location.href =
        "https://we.xyzzzzz.com";
    },
    initVideo() {
      //初始化视频方法
      this.myPlayer = Video(myVideo, {
        //确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
        controls: false,
        //自动播放属性,muted:静音播放
        autoplay: true,
        muted:true,
        //建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
        preload: "auto",
        //设置视频播放器的显示高度（以像素为单位）
        height: "500px",
        poster: this.video.videoUrl+'?x-oss-process=video/snapshot,t_1000,f_jpg,w_240,h_135,m_fast', 
        sources: [//视频播放源，建议本地
          {
            src: this.video.videoUrl
          }
        ]
      });
    },
    playVideo() {
      if (this.myPlayer) {
        if (this.myPlayer.paused() == true) {
          this.myPlayer.play();
        } else {
          this.myPlayer.pause();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  background-color: black;
  position: relative;
  padding-bottom: 80px;
  width: 100%;
  .top-bar {
    z-index: 9999;
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 55px;
    background: #333333;
    font-size: 15px;
    font-weight: 500;
    color: #ffffff;
    line-height: 20px;
    .logo {
      width: 40px;
      height: 40px;
      margin: 0 17px;
    }
    .info {
      flex: 1;
      text-align: left;
    }

    .open-btn {
      width: 99px;
      height: 100%;
      background: #ff8021;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .video-info {
    height: 500px;
    width: 100%;
    position: relative;
    .video-cover {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background-color: transparent;
      .right {
        position: absolute;
        right: 20px;
        bottom: 50px;
        width: 100px;
        // background-color: #ff8021;
        display: flex;
        flex-direction: column;
        align-items: center;
        .head {
          width: 52px;
          height: 52px;
          border-radius: 52px;
          margin-bottom: 4px;
        }
        .like {
          width: 35px;
          height: 35px;
          border-radius: 4px;
          margin-top: 20px;
          margin-bottom: 4px;
        }
        .text {
          font-size: 14px;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .left-bottom {
        position: absolute;
        right: 120px;
        left: 20px;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: flex-start;
        .bottom-btn {
          width: 100%;
          height: 51px;
          background: linear-gradient(288deg, #e46f34 0%, #ff8521 100%);
          border-radius: 7px 7px 7px 7px;
          opacity: 1;
          font-size: 16px;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: bold;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .bottom-desc {
          text-align: left;
          margin-top: 20px;
          margin-bottom: 20px;
          font-size: 14px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
        .bottom-title {
          font-size: 22px;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: bold;
          color: #ffffff;
        }
      }
    }
    #myVideo{
      width: 100%;
    }
  }
  .tj-video {
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    .tj-hx {
      width: 48px;
      height: 2px;
      background: #ffffff;
      border-radius: 0px 0px 0px 0px;
      opacity: 0.5;
    }
    .tj-text {
      font-size: 20px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
      margin-left: 20px;
      margin-right: 20px;
    }
  }
  .xianhuo-list {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    .xianhuo-item {
      margin-bottom: 12px;
      width: 182px;
      border-radius: 5px 5px 0px 0px;
      padding-bottom: 10px;
      background: #1d1d1d;
      border-radius: 2px 2px 2px 2px;
      .xianhuo-img {
        width: 182px;
        height: 164px;
        background: #d8d8d8;
        border-radius: 5px 5px 0px 0px;
        opacity: 1;
        img{
          width: 182px;
        height: 164px;
        }
      }
      .xianhuo-name {
        padding-left: 12px;
        padding-right: 12px;
        margin-top: 10px;
        margin-bottom: 6px;
        font-size: 12px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        text-align: left;
      }

      .xianhuo-address-year {
        padding-left: 12px;
        padding-right: 12px;
        border-radius: 5px 5px 0px 0px;
        display: flex;
        font-size: 12px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        align-items: center;
        .bottom-item {
          flex: 1;
          text-align: left;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .head{
            width: 10px;
            height: 10px;
            margin-right: 5px;
          }
        }
        .right{
          justify-content: flex-end;
        }
      }
    }
  }
  .bottom {
    position: fixed;
    left: 22px;
    right: 22px;
    bottom: 20px;
    width: 370px;
    height: 51px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(288deg, #e46f34 0%, #ffa357 100%);
    border-radius: 7px 7px 7px 7px;
    opacity: 1;
    font-size: 14px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
    .free {
      font-size: 18px;
    }
  }
}
</style>