<template>
  <div class="content">
    <div class="top-bar">
      <img class="logo" src="@/assets/icon.png" alt="icon" />
      <div class="info">打开粮策APP，了解市场行情</div>
      <div v-if="isWX">
        <wx-open-launch-app
          id="launch-btn"
          appid="wx66c7689c38662fd9"
          :extinfo="extinfo"
        >
          <component :is="'script'" type="text/wxtag-template">
            <div
              style="
                width: 99px;
                height: 55px;
                background: #ff8021;
                color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
              "
              class="btn"
            >
              立即打开
            </div>
          </component>
        </wx-open-launch-app>
      </div>
      <div v-else @click="openApp" class="open-btn">立即打开</div>
    </div>
    <div class="zixun">
      <div class="zixun-name">{{zixun.title}}</div>
      <div class="zixun-source-time">
        <div class="source">来源：{{zixun.origin}}</div>
        <div class="time">{{zixun.createTime}}</div>
      </div>
      <div class="zixun-content" v-html="zixun.content"></div>
    </div>
    <div class="xianhuo">
      <div class="xianhuo-top">
        <div class="top-left"></div>
        <div class="top-title">现货信息</div>
      </div>
      <div class="xianhuo-list">
        <div @click="openApp" v-for="(item, index) in zixun.merchandiseFineList" v-bind:key="index" class="xianhuo-item">
          <div class="xianhuo-img">
            <img :src="item.images" alt="" />
          </div>
          <div class="xianhuo-name">{{item.name}}</div>
          <div class="xianhuo-price-num">
            <div class="price">¥{{item.price}}<span class="dw">{{item.productUnit==2?'/吨':'/斤'}}</span></div>
            <div class="num">{{item.numbers}}吨</div>
          </div>
          <div class="xianhuo-hx"></div>
          <div class="xianhuo-address-year">
            <div class="address">{{getAddress(item.address)}}</div>
            <div class="year">{{item.year}}</div>
          </div>
        </div>
      </div>
    </div>
    <div @click="openApp" class="bottom">
      打开粮策APP联系商家，发布信息<span class="free">都免费</span>
    </div>
    <div v-if="isNoPhone" class="no-vip"></div>
    <div v-if="isNoPhone" class="no-vip-info">
      <div class="no-title">完善信息，阅读全文</div>
      <div class="login">
      <div class="row-item">
        <van-field maxlength="11" type="tel" v-model="phone" placeholder="请输入手机号" />
      </div>
      <div class="row-item">
        <van-field maxlength="6" type="number" v-model="sms" center clearable placeholder="请输入短信验证码">
          <template #button>
            <div @click="getPhoneCode" class="code-btn">{{codeMsg}}</div>
          </template>
        </van-field>
      </div>
    </div>
      <div @click="lookZixun" class="no-btn">查看全文</div>
    </div>
  </div>
</template>

<script>
import { Field, showToast, showLoadingToast, closeToast } from 'vant'
import { wechatSign } from "@/api/wechat/wechat"
import { getcode, login, getLoginInfo } from '@/api/request'

import { getZixunDetail } from "@/api/zixun/zixun";
export default {
  data() {
    return {
        isNoPhone:true,
        phone:'',
        content:"",
        zixun:{},
        shareId:'',
        shareTitle:'粮策-买卖存粮有策略',
        shareDesc:'粮食生意不好做，看不透市场，就来粮策！为您的粮食生意保驾护航！',
        isWX:false,
        extinfo:'',
        sms:'',
        codeMsg: '发送验证码',
        lcID: '',
        invite:'',
        userInfo:{}
    };
  },
  components: {
    [Field.name]: Field
  },
  mounted(){
    var ua = navigator.userAgent.toLowerCase(); //判断是否是微信浏览器
    this.isWX = ua.indexOf("micromessenger") != -1; //判断是否     
    
    this.lcID = this.$route.query.lcID
    this.shareId = this.$route.query.shareId
    if(this.isWX){
      this.wxSign();
    }
    this.getDetail();
    this.getUser()
    let phone = localStorage.getItem('zixun-phone');
    if(phone!=null&&phone!=undefined&&phone!=''){
      this.isNoPhone = false;
      document.body.style.overflow = "visible";
    }else{
      this.isNoPhone = true;
      document.body.style.overflow = "hidden";
    }
  },
  methods: {
    getAddress(ad){
      if(ad!=null&&ad!=undefined){
          let arr = ad.split(' ')
          if(arr.length>=3){
            return arr[0] + ' ' + arr[1] + ' '+arr[2]
          }else{
           return  ad
          }
        }
    },
     wxSign(){
      let info = {
        'title':this.shareTitle,
        'desc':this.shareDesc,
        'link':window.location.href,
      }
      wechatSign(window.location.href.split("#")[0],info)
    },
    openApp() {
      window.location.href =
        "https://we.xyzzzzz.com";
    },
    async getDetail(){
      let res = await getZixunDetail(this.shareId)
      if(res.code == 200){
        this.zixun = res.data;
        this.extinfo = 'lcapp://app?shareType=0&shareId=' + this.shareId;
      }
    },
    async lookZixun(){
      if(this.phone.length==0){
        showToast('请输入手机号')
        return;
      }
      const phoneReg = /^1[3456789]\d{9}$/
      if (!phoneReg.test(this.phone)) {
        showToast('请输入正确的手机号')
        return;
      }
      if (this.phone.length == 0 || this.sms.length == 0) {
        showToast('请输入手机号或验证码')
        return
      }
      showLoadingToast()
      let res = await login(this.phone, this.sms, this.invite)
      // if (res.code == 200) {
        closeToast()
        localStorage.setItem('zixun-phone',this.phone)
      this.isNoPhone = false;
      document.body.style.overflow = "visible";
      // }
    },
    async getUser() {
      if (this.lcID != null && this.lcID.length != 0) {
        let res = await getLoginInfo(this.lcID)
        if (res.code == 200) {
          this.userInfo = res.data
          this.invite = res.data.invite
        }
      } else {
        // this.$router.push('/redirect')
      }
    },
    startCountDownTimer() {
      var that = this
      this.num = 60
      this.countdownTimer = setInterval(function () {
        if (that.num > 0) {
          that.num = that.num--
          that.codeMsg = `等待 ${that.num--}s`
        } else {
          that.codeMsg = '发送验证码'
          clearInterval(that.countdownTimer)
        }
      }, 1000)
    },
    async getPhoneCode() {
      if (this.codeMsg != '发送验证码') {
        return
      }
      if (this.phone.length == 0) {
        showToast('请输入手机号')
        return
      }
      showLoadingToast()
      let res = await getcode(this.phone)
      if (res.code == 200) {
        closeToast()
        this.startCountDownTimer()
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
    height: 100%;
  background-color: #efefef;
  position: relative;
  padding-bottom: 80px;
  width: 100%;
  .top-bar {
    background-color: white;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 55px;
    background: #333333;
    font-size: 15px;
    font-weight: 500;
    color: #ffffff;
    line-height: 20px;
    .logo {
      width: 40px;
      height: 40px;
      margin: 0 17px;
    }
    .info {
      flex: 1;
      text-align: left;
    }

    .open-btn {
      width: 99px;
      height: 100%;
      background: #ff8021;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .zixun {
    background-color: white;
    padding: 74px 20px 20px 20px;
    .zixun-name {
      font-size: 22px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #000000;
      text-align: left;
    }
    .zixun-source-time {
      margin-top: 6px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .source {
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #000000;
      }
      .time {
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #000000;
      }
    }
    .zixun-content {
      padding: 12px 0 12px 0;
      font-size: 18px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #363636;
      text-align: left;
    }
     ::v-deep img{
         width: 100%;
     }
  }
  .xianhuo {
    padding-left: 20px;
    padding-right: 20px;
    .xianhuo-top {
      margin-top: 16px;
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      .top-left {
        width: 7px;
        height: 26px;
        background: #ff7916;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
      }
      .top-title {
        margin-left: 10px;
        font-size: 20px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #000000;
      }
    }
    .xianhuo-list {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      .xianhuo-item {
        margin-bottom: 12px;
        width: 182px;
        border-radius: 5px 5px 0px 0px;
        background-color: white;
        padding-bottom: 10px;
        .xianhuo-img {
          width: 182px;
          height: 164px;
          background: #d8d8d8;
          border-radius: 5px 5px 0px 0px;
          opacity: 1;
          img{
            width: 182px;
          height: 164px;
          }
        }
        .xianhuo-name {
          padding-left: 12px;
          padding-right: 12px;
          margin-top: 10px;
          margin-bottom: 6px;
          font-size: 12px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #000000;
          text-align: left;
        }
        .xianhuo-price-num {
          padding-left: 12px;
          padding-right: 12px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .price {
            font-size: 15px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #ff1818;
            .dw {
              font-size: 10px;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: #000000;
            }
          }
          .num {
            font-size: 10px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #000000;
          }
        }
        .xianhuo-hx {
          margin-left: 8px;
          margin-top: 10px;
          margin-bottom: 10px;
          width: 170px;
          height: 1px;
          background: #e9e8e9;
          opacity: 1;
        }
        .xianhuo-address-year {
          padding-left: 12px;
          padding-right: 12px;
          border-radius: 5px 5px 0px 0px;
          display: flex;
          font-size: 10px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #000000;
          align-items: center;
          .address {
            flex: 1;
            text-align: left;
          }
        }
      }
    }
  }
  .bottom {
    position: fixed;
    left: 22px;
    right: 22px;
    bottom: 20px;
    width: 370px;
    height: 51px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(288deg, #e46f34 0%, #ffa357 100%);
    border-radius: 7px 7px 7px 7px;
    opacity: 1;
    font-size: 14px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
    .free {
      font-size: 18px;
    }
  }
  .no-vip{
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
}
.no-vip-info{
  position: fixed;
  background-color: white;
display:flex;
z-index: 99999;
left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  .no-title{
    padding-top: 20px;
    text-align: left;
    width: 370px;
    font-size: 18px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: black;
  }
  .row-item{
    width: 370px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #eeeeee;
  }
  .no-btn{
    background: linear-gradient(288deg, #e46f34 0%, #ffa357 100%);
    border-radius: 7px 7px 7px 7px;
     width: 370px;
     height: 51px;
     display: flex;
     justify-content: center;
  align-items: center;
  font-size: 14px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 40px;
  }
}
.login {
  .code-btn {
    text-align: center;
    color: #ff8021;
    font-weight: bold;
  }
  .row-item {
    height: 54px;
    background: #f0f0f0;
    border-radius: 3px 3px 3px 3px;
    opacity: 1;
    margin-bottom: 10px;
  }
  .reg-btn {
    margin-top: 30px;
    width: 334px;
    height: 51px;
    background: #fa6e28;
    border-radius: 116px 116px 116px 116px;
    opacity: 1;
    border: 1px solid rgba(255, 255, 255, 0.45);
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
}
</style>