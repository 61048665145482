<template>
  <div class="privacy">
    <h2>用户协议</h2>
    <p>
      欢迎阅读德州微派网络科技有限公司注册协议（下称“本协议”）。本协议阐述之条款和
      条件适用于您通过本公司中文网站（所涉域名为：www.liangce.cc下同，包括网站、APP、
      H5页面、微信小程序等我们提供的一切产品)使用德州微派网路科技有限公司（以下简称
      “本公司”)所提供的各种服务（下称“服务”）。
    </p>
    <h3>一、接受条款</h3>
    <p>
      1.请认真阅读本条款，当您注册、登录、浏览或使用粮策网的任何功能，或在中华粮
      网上发布任何内容，均意味着您同意并完全接受本协议的全部条款和条件（以下简称“条
      款”)约束。如果您对本条款表示异议，您可以选择不进入粮策网或不使用粮策网。
    </p>
    <p>
      2.本公司可随时自行全权决定更改条款。如条款有任何变更，将在网站上刊载公告，粮策网的通知、公告、声明或其他类似内容是本协议的一部分。如您不同意相关变更，必须
      停止使用服务。经修订的条款一经公布，立即自动生效。您登录或继续使用服务将表示您接
      受经修订的条款。除另行明确声明外，任何使服务范围扩大或功能增强的新内容均受本协议
      约束。
    </p>
    <p>
      3.如果你未满18周岁或依法被限制行为能力，请在法定监护人的陪同下阅读本协议，
      并特别注意未成年人使用条款。
    </p>
    <h3>二、服务内容</h3>
    <h4>1.粮策网为您提供下列服务：</h4>
    <p>粮油市场信息搜索及浏览、收藏，包括VIP信息内容浏览</p>
    <p>报告查看，包括免费下载和使用VIP账号下载</p>
    <p>数据查看，包括免费下载和使用VIP账号下载</p>
    <p>购买VIP会员</p>
    <p>联系商家</p>
    <p>纸质名片</p>
    <p>视频点播</p>
    <h4>2.您理解并同意，因业务发展需要，本公司保留单方面对本服务的全部或部分服务内容在任何时候不经任何通知的情况下变更、暂停、限制、终止或撤销的权利。</h4>
    <h4>3.如您需要开具发票，请联系平台客服，粮策网将为您开具正规有效发票。</h4>
    <h4>
      4.VIP会员服务为虚拟+实体服务，您理解并接受一经开通后不可转让或退款。粮策网拥有
      最终解释权。粮策网在此特别提醒您，您在购买VIP会员服务之前应仔细核对账号信息、
      购买的服务内容、价格、服务期限等信息。
    </h4>
    <h3>三、使用规则</h3>
    <p>1.您在使用粮策网前需要注册一个账号，该账号由微信账号或手机号绑定生成。账号注册成功后，您应当对该账号的所有活动和事件负法律责任。</p>
    <p>2.粮策网将对您的注册账号信息进行审查。您须对您提供的账号资料的真实性、合法性、准确性、有效性承担全部责任。如因此给本公司或第三方造成损害的，您应当依法子以赔偿。</p>
    <p>
      3.您的账号的所有权归本公司所有，您完成申请注册手续后，将获得所注册账号的使用
      权，该使用权仅限于您使用，您无权赠与、借用、租用、转让或售卖您的账号。您应当妥善
      保管账户及密码信息，由于您自身行为导致的账户或密码的泄露、遗忘等情形或其他原因所
      带来的损失，粮策网将尽量配合您采取积极措施降低相关损失，但因此造成的不利后果由
      您自行承担。
    </p>
    <p>4.在使用粮策网的过程中，您同意严格遵守以下义务:不得传输或发表:煽动抗拒、破坏宪法和法律、行政法规实施的言论，煽动颠覆国家政权，推翻社会主义制度的言论，煽动分裂国家、破坏国家统一的言论，煽动民族仇恨、民族歧视、破坏民族团结的言论;不得利用粮策网从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动:不得干扰粮策网的正常运转，不得侵入粮策网相关的运作系统，包括但不限于服务器、数据库、公司内部计算机系统及国家计算机信息系统:不得传输或发表任何违法犯罪的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽的、不文明的等信息资料:不得传输或发表损害国家社会公共利益和涉及国家安全的信息资料或言论;不得教暧他人从事本条所禁止的行为:不得发布任何侵犯他人隐私、个人信息、著作权、商标权等知识产权或合法权利的内容:不得冒充他人、或利用他人名义发布信息、或恶意使用注册帐号导致其他用户误认:不得以任何方式利用粮策网直接或间接从事违反中国法律、以及社会公德的行为。本公司有权对您使用粮策网的情况进行审查和监督,如您在使用粮策网时违反任何上述规定，本公司有权立即停止向您提供服务，收回帐号并由您独自承担由此而产生的一切法律责任，粮策网的系统记录有可能作为您违反法律的证据。</p>
    <p>5.您理解并同意，本服务仅为您提供报告分享、传播及获取的平台，您必须为自己注册帐户下的一切行为负责，包括您所发表内容的真实性、合法性、准确性、有效性，以及承担因本服务使用行为产生的结果。您应自行保证不侵犯或泄露他人隐私，且不侵犯他人的知识产权，您应自行对此负责。</p>
    <p>6.出于下列目的之一，本公司有权对您发布的公开信息(包括供求信息等)的全部或部分进行删减、修订、隐藏处理，同时保留进一步追索的权利:本公司认为您发布的信息可能涉嫌侵犯他人知识产权或隐私时;用户投诉或存在着争议时;您发布的信息可能对用户造成困扰、不便或误导时;您有其他违反国家法律、法规的行为。</p>
    <p>7.基于维护粮策各类产品与服务的秩序及交易的安全的需要，粮策有权在发省恶意炒作扰乱市场正常交易的秩序下，执行风景相关账号等操作</p>
    <h3>四、用户信息</h3>
    <p>1.您知悉并同意，为方便您使用粮策网相关服务，粮策网将存储您在使用时的必要信息，包括但不限于您的微信账号资料、真实姓名、性别、生日、联系方式等。除法律法规规定的情形外，未经您的许可本公司不会向第三方公开、透露您的个人信息或您在使用粮策网服务时存储的非公开内容。同时，为了运营和改善粮策网的技术与服务，本公司将可能会自行收集使用提供您的非个人隐私信息,这将有助于本公司向您提供更好的用户体验和服务质量。本公司对相关信息采取专业加密存储与传输方式，利用合理措施保障用户个人信息的安全。</p>
    <p>
      2.您充分理解并同意:接收通过邮件、短信、电话等形式向您发送的促销活动等内容;为配合行政监管机关、司法机关执行工作，在法律规定范围内本公司有权向上述行政、司法机关提供您在使用粮策网时所储存的相关信息，包括但不限于您的注册信息等，或使用相关信息进行证据保全，包括但不限于公证、见证等;本公司依法保障您在安装或使用过程中的知情权和选择权,在您使用粮策网服务过程中，涉及您设备自带功能的服务会提前征得您同意，您一经确认，粮策网有权开启提供服务必要的辅助功能。
      本公司有权根据实际情况在法律规定范围内自行决定单个用户在粮策网及服务中数据的最长储存期限以及用户日志的储存期限，并在服务器上为其分配数据最大存储空间等。
    </p>
    <p>3.本公司非常重视对未成年人个人信息的保护。若您是 18 周岁以下的未成年人或依法被限制行为能力，在使用粮策网前，应确保事先取得监护人的同意，如您在本平台上申请注册账号，本公司将默认为您已得到前述同意。本公司将根据国家相关法律法规及本协议的规定保护未成年人的个人信息。</p>
    <h3>五、免责声明</h3>
    <p>1.粮策网中公开或共享的信息、报告、条款及其简介、评论(以下简称公开文本)。无论该公开文本是免费或需要 VIP 权限下载使用，无论该文本与信息系由本公司提供或由用户提供，无论是否经过粮策网平台编辑、修订，均在本免责声明范围之内。</p>
    <p>2.本网站中的公开文本一般系依据中华人民共和国(港澳台地区除外)法律法规而拟定未必适用于其它国家地区。即使在中华人民共和国范围内,亦根据本文件享有相应免责权利。</p>
    <p>3.粮策网是一个粮食信息资讯及数据服务平台，其上发布的信息、报告、数据不构成对您的投资建议，您充分知悉并了解投资风险，所有投资事项均是依据自己的理解而进行本公司不对您的投资行为承担任何责任。</p>
    <p>4.粮策网致力于提升信息、报告、数据的质量,但仍不能保证本网站中的信息、报告数据不存在法律、信息真实性上的错误。在任何情况下,不得因使用或依赖本网站上的信息、报告、数据而导致的任何损失要求本公司承担责任，亦不得要求提供此信息的粮策网用户承担责任。无论用户系免费或使用 VIP 权限下载或使用，均适用本免责声明。</p>
    <p>5.本公司不能对用户在网站发布的信息的正确性进行保证。用户在粮策网发表的内容仅表明其个人的立场和观点，并不代表本公司的立场或观点。作为内容的发表者，需自行对所发表内容负责，因所发表内容引发的一切纠纷，由该内容的发表者承担全部法律及连带责任。本公司不承担任何法律及连带责任</p>
    <h3>六、知识产权</h3>
    <p>1.粮策网是一个粮油信息资讯及数据服务平台，平台上转载的公开信息、报告等的知识产权归其原作者所有。粮策网在本服务内提供的内容(包括但不限于文字、图片、音频、视频、图表、数据等)的知识产权均归本公司所有。未经本公司许可，任何人不得擅自使用(包括但不限于通过任何机器人、蜘蛛等程序或设备监视、复制、传播、展示、镜像、上载、下载粮策网内的任何内容)。</p>
    <p>2.除另有特别声明外，本公司提供本服务时所依托软件的著作权、专利权及其他知识产权均归本公司所有。本公司在本服务中使用的“粮策”等商业标识，其著作权归本公司所有。未经粮策书面授权，任何人不得以任何方式展示、使用或作其他处理，也不得向他人表明您有权展示、使用或作其他处理。</p>
    <h3>七、违约责任</h3>
    <p>1.如果本公司发现或收到他人举报投诉您违反本协议约定的,本公司有权不经通知随时对相关内容进行删除、屏蔽，并视行为情节对违规帐号处以包括但不限于警告、限制或禁止使用部分或全部功能、帐号封禁直至注销的处罚，并公告处理结果。</p>
    <p>2.本公司有权依据合理判断对违反有关法律法规或本协议规定的行为采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，您应独自承担由此而产生的一切法律责任。</p>
    <p>3.您理解并同意，因您违反本协议或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，您应当独立承担责任:本公司因此遭受损失的，您也应当一并赔偿。</p>
    <p>4.除非另有明确的书面说明,本公司不对粮策网的运营及其包含在粮策上的信息内容或服务作任何形式的、明示或默示的声明或担保(根据中华人民共和国法律另有规定的以外)。</p>
    <h3>八、不可抗力</h3>
    <p>对于因本公司合理控制范围以外的原因，包括但不限于自然灾害、罢工或骚乱、物质短缺或定量配给、暴动、战争行为、政府行为、通讯或其他设施故障或严重伤亡事故等，致使本公司延迟或未能履约的，本公司不对您承担任何责任。</p>
    <h3>九、转让</h3>
    <p>本公司转让本协议无需经您同意。</p>
    <h3>十、法律管辖适用及其他</h3>
    <p>1.本协议的订立、执行和解释及争议的解决均应适用中国法律。如双方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决:协商不成时，可向本公司所在地人民法院提起诉讼，并以中华人民共和国法律为管辖法律。</p>
    <p>2.本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。</p>
    <p>3.如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。</p>
    <p>4.本协议未明示授权的其他权利仍由本公司保留，您在行使这些权利时须另外取得本公司的书面许可。本公司如果未行使前述任何权利，并不构成对该权利的放弃。</p>
  </div>
</template>

<script>
export default {
  name: 'LcvueUserProtocol',

  data() {
    return {}
  },

  mounted() {},

  methods: {}
}
</script>

<style lang="scss" scoped>
.privacy {
  padding: 40px;
  text-align: left;
}
</style>