import request from "@/api/index.js";

export function getVideoDetail(vid) {
  return new Promise(resove => {
    request({
      url: `/video/videodetail/` + vid,
      method: "get",
    }).then(res => {
      resove(res.data);
    });
  });
}
