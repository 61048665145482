<template>
  <div class="content">
    <div class="title">邀请有礼</div>
    <div class="desc">现金红包等你拿</div>
    <div class="share-info">
      <div id="copyBtn" @click="copy" class="invite">{{invite}}</div>
      <vue-qr class="myewm" :logoSrc="iconUrl" :text="myUrl" :size="160"></vue-qr>
      <div class="ewm-sb">长按二维码识别</div>
    </div>
    <div class="saveBtn" @click="openApp">下载APP</div>
    <img class="ewm-bottom" src="@/assets/share/erm_bottom.png" />
  </div>
</template>

<script>
import Clipboard from 'clipboard'
import { showToast } from 'vant'
import { getLoginInfo } from '@/api/request'
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import { wechatSign } from "@/api/wechat/wechat"
export default {
  name: 'LcvueShareNormal',

  data() {
    return {
      lcID: '',
      invite: ' ',
      myUrl: 'https://ios.liangce.cc/#/',
      iconUrl: require('../../assets/icon.png'),
      shareId:'',
        shareTitle:'粮策-买卖存粮有策略',
        shareDesc:'粮食生意不好做，看不透市场，就来粮策！为您的粮食生意保驾护航！'
    }
  },
  components: {
    vueQr
  },

  mounted() {
    var ua = navigator.userAgent.toLowerCase(); //判断是否是微信浏览器
    this.isWX = ua.indexOf("micromessenger") != -1; //判断是否     
    if(this.isWX){
      this.wxSign();
    }
    this.lcID = this.$route.query.lcID
    this.getUser()
  },

  methods: {
    wxSign(){
      let info = {
        'title':this.shareTitle,
        'desc':this.shareDesc,
        'link':window.location.href,
      }
      wechatSign(window.location.href.split("#")[0],info)
    },
    async getUser() {
      if (this.lcID != null && this.lcID.length != 0) {
        let res = await getLoginInfo(this.lcID)
        if (res.code == 200) {
          this.invite = res.data.invite
        }
      } else {
      }
      this.myUrl = 'https://we.xyzzzzz.com'
    },
    goto() {
      this.$router.push({
        path: '/hello'
      })
    },
    copy() {
      // 因为此事件不能添加.stop，所以要设置一个开关, 500ms后打开开关
      let str = this.invite
      let clipboard = new Clipboard('#copyBtn', {
        text: function () {
          return str
        }
      })
      clipboard.on('success', () => {
        showToast('复制成功')
        clipboard.destroy()
      })
    },
    openApp() {
      window.location.href = 'https://we.xyzzzzz.com'
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  color: #000;
  min-height: 100vh;
  background: url('../../assets/share/share_bg.png') center center no-repeat;
  background-size: 100% 100%;
  position: absolute; //绝对定位
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  font-size: 30px;
  font-family: Source Han Sans CN-Heavy, Source Han Sans CN;
  font-weight: 800;
  color: #ffffff;
  margin-top: 50px;
}
.desc {
  font-size: 16px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  margin-top: 5px;
  margin-bottom: 15px;
}
.share-info {
  width: 350px;
  height: 420px;
  background-color: green;
  color: #000;
  background: url('../../assets/share/ewm_bg.png') center center no-repeat;
  background-size: 100% 100%;
  .invite {
    font-size: 34px;
    font-weight: 900;
    color: #e13b3b;
    line-height: 40px;
    text-shadow: 0px 4px 10px rgba(65, 4, 2, 0.09);
    margin-top: 13px;
  }
  .myewm {
    margin-top: 56px;
    margin-bottom: 5px;
  }
}
.saveBtn {
  margin-top: 20px;
  width: 164px;
  height: 51px;
  background: #ff4e3d;
  box-shadow: 0px 4px 10px 0px rgba(124, 14, 3, 0.26), inset 0px 1px 2px 0px rgba(252, 252, 252, 0.31);
  border-radius: 116px 116px 116px 116px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
}
.ewm-bottom {
  margin-top: 30px;
  width: 80vw;
}
</style>