<template>
  <div class="content" contenteditable="true">
    <div @click="openApp">调用原生</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  mounted() {
    
  },
  methods: {
    openApp(){
      let res = window.appSdk.double(10);
      print('res===',res)
    }
  },
};
</script>

<style lang="scss" scoped>
.content{
  align-items: center;
  align-content: center;
  // background-color: red;
  min-height: 60px;
}
</style>