<template>
  <div class="content">
    <img mode="scaleToFill" class="def-bg" src="@/assets/share/yaoqing_bg.png" />
    <div class="top-bar">
      <img class="logo" src="@/assets/icon.png" alt="icon" />
      <div class="info">打开粮策APP，了解市场行情</div>
      <div v-if="isWX">
        <wx-open-launch-app
          id="launch-btn"
          appid="wx66c7689c38662fd9"
          :extinfo="extinfo"
        >
          <component :is="'script'" type="text/wxtag-template">
            <div
              style="
                width: 99px;
                height: 55px;
                background: #ff8021;
                color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
              "
              class="btn"
            >
              立即打开
            </div>
          </component>
        </wx-open-launch-app>
      </div>
      <div v-else @click="openApp" class="open-btn">立即打开</div>
    </div>
    <div class="login">
      <div class="title">{{userInfo.address!=null? userInfo.address.region:''}}{{userInfo.name}}邀请您注册</div>
      <div class="row-item">
        <van-field maxlength="11" type="tel" v-model="phone" placeholder="请输入手机号" />
      </div>
      <div class="row-item">
        <van-field maxlength="6" type="number" v-model="sms" center clearable placeholder="请输入短信验证码">
          <template #button>
            <div @click="getPhoneCode" class="code-btn">{{codeMsg}}</div>
          </template>
        </van-field>
      </div>
      <div @click="regin" class="reg-btn">立即注册</div>
    </div>
  </div>
</template>

<script>
import { wechatSign } from "@/api/wechat/wechat"
import { Field, showToast, showLoadingToast, closeToast } from 'vant'
import { getcode, login, getLoginInfo } from '@/api/request'
export default {
  name: 'LcvueInviteUser',

  data() {
    return {
      phone: '',
      sms: '',
      invite: '',
      lcID: '',
      codeMsg: '发送验证码',
      num: 60,
      userInfo: {
        'address':{
  
        }
      },
       shareId:'',
        shareTitle:'粮策-买卖存粮有策略',
        shareDesc:'粮食生意不好做，看不透市场，就来粮策！为您的粮食生意保驾护航！',
         isWX:false,
        extinfo:''
    }
  },
  components: {
    [Field.name]: Field
  },

  mounted() {
    var ua = navigator.userAgent.toLowerCase(); //判断是否是微信浏览器
    this.isWX = ua.indexOf("micromessenger") != -1; //判断是否     
    if(this.isWX){
      this.wxSign();
    }
    this.lcID = this.$route.query.lcID
    this.getUser()
  },

  methods: {
    wxSign(){
      let info = {
        'title':this.shareTitle,
        'desc':this.shareDesc,
        'link':window.location.href,
      }
      wechatSign(window.location.href.split("#")[0],info)
    },
    openApp() {
      window.location.href = 'https://we.xyzzzzz.com'
    },
    async getUser() {
      if (this.lcID != null && this.lcID.length != 0) {
        let res = await getLoginInfo(this.lcID)
        if (res.code == 200) {
          this.userInfo = res.data
          this.invite = res.data.invite
        }
      } else {
        // this.$router.push('/redirect')
      }
    },
    startCountDownTimer() {
      var that = this
      this.num = 60
      this.countdownTimer = setInterval(function () {
        if (that.num > 0) {
          that.num = that.num--
          that.codeMsg = `等待 ${that.num--}s`
        } else {
          that.codeMsg = '发送验证码'
          clearInterval(that.countdownTimer)
        }
      }, 1000)
    },
    async getPhoneCode() {
      if (this.codeMsg != '发送验证码') {
        return
      }
      if (this.phone.length == 0) {
        showToast('请输入手机号')
        return
      }
      showLoadingToast()
      let res = await getcode(this.phone)
      if (res.code == 200) {
        closeToast()
        this.startCountDownTimer()
      }
    },
    async regin() {
      if (this.phone.length == 0 || this.sms.length == 0) {
        showToast('请输入手机号或验证码')
        return
      }
      showLoadingToast()
      let res = await login(this.phone, this.sms, this.invite)
      if (res.code == 200) {
        closeToast()
        showToast('注册成功')
        // this.$router.push('/redirect')
        window.location.href = 'https://we.xyzzzzz.com'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.def-bg {
  width: 100%;
  position: absolute;
  top: 55px;
  z-index: -1;
}
.top-bar {
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 55px;
  background: #333333;
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  line-height: 20px;
  .logo {
    width: 40px;
    height: 40px;
    margin: 0 17px;
  }
  .info {
    flex: 1;
    text-align: left;
  }

  .open-btn {
    width: 99px;
    height: 100%;
    background: #ff8021;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.login {
  padding: 40px;
  margin-top: 100px;
  width: 340px;
  .title {
    font-size: 24px;
    font-weight: bold;
    color: #343232;
    margin-bottom: 35px;
  }
  .code-btn {
    text-align: center;
    color: #ff8021;
    font-weight: bold;
  }
  .row-item {
    width: 342px;
    height: 54px;
    background: #f0f0f0;
    border-radius: 3px 3px 3px 3px;
    opacity: 1;
    margin-bottom: 10px;
  }
  .reg-btn {
    margin-top: 30px;
    width: 334px;
    height: 51px;
    background: #fa6e28;
    border-radius: 116px 116px 116px 116px;
    opacity: 1;
    border: 1px solid rgba(255, 255, 255, 0.45);
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
<style>
.van-cell {
  background-color: transparent;
  height: 100%;
  padding: auto 20px;
  font-size: 16px;
  color: #000;
}
</style>