<template>
  <div class="content">
    <van-tabs
      title-inactive-color="white"
      title-active-color="white"
      background="#ea6b2b"
      color="white"
      class="top-bar"
      sticky="true"
      v-model:active="active"
    >
      <van-tab v-for=" (item,index) in list" :key="index" :title="item.factoryPriceType">
        <lcvue-shop-priceItem :typeId="item.id" :isNovip="isNovip"></lcvue-shop-priceItem>
      </van-tab>
    </van-tabs>
    <div v-if="isNoVip" class="no-vip"></div>
    <div v-if="isNoVip" class="no-vip-center">
        <div class="center-name">全国报价市场</div>
        <div class="center-name">历史价格走势</div>
        <div @click="openVip()" class="center-btn">查看历史价格</div>
      </div>
    <div  v-if="isNoVip" class="no-vip-cotent">
        <div @click="openVip()" class="bottom-btn">查看更多行情</div>
      </div>
  </div>
</template>

<script>
import { Tab, Tabs } from 'vant'
import LcvueShopPriceItem from '@/components/discover/shop_price_item.vue'
import { getFactoryPriceTypes } from '@/api/request'
export default {
  name: 'LcvueShopPrice',
  data() {
    return {
      active: 0,
      showBottom: true,
      list: [],
      isNoVip:true
    }
  },
  components: {
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    LcvueShopPriceItem
  },

  mounted() {
    window.setVip = this.setVip;
    localStorage.setItem('token', this.$route.query.token)
    let isvip = this.$route.query.isvip;
    if(isvip==1){
      this.isNoVip = false
      document.body.style.overflow = "visible";
    }else{
      this.isNoVip = true
      document.body.style.overflow = "hidden";
    }
    this.getTypes()
  },

  methods: {
    setVip(vip){
      if(vip==1){
        this.isNoVip = false;
        document.body.style.overflow = "visible";
      }
    },
    openVip(){
      APPOpenVip.postMessage('')
    },
    async getTypes() {
      let res = await getFactoryPriceTypes()
      if (res.code == 200) {
        this.list = res.data
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  width: 100vw;
}
.no-vip{
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
}
.no-vip-cotent {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
    height: 270px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8409) 43%,
      #ffffff 100%
    );
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    .bottom-btn {
      margin-bottom: 43px;
      width: 284px;
      height: 53px;
      background: linear-gradient(270deg, #f6a456 0%, #f1793c 100%);
      border-radius: 17px 17px 17px 17px;
      opacity: 1;
      font-size: 20px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
.no-vip-center {
    position: absolute;
    left: 0;
    right: 0;
    top: 15px;
    height: 530px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6411) 35%,
      #ffffff 100%
    );
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .center-name {
      font-size: 24px;
      font-family: YouSheBiaoTiHei-Bold, YouSheBiaoTiHei;
      font-weight: 700;
      color: #444444;
      margin-bottom: 10px;
    }
    .center-btn {
      width: 164px;
      height: 37px;
      background: #f07436;
      opacity: 1;
      font-size: 16px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 10px 10px 10px 10px;
    }
  }
</style>