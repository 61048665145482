<template>
  <div id="shopPriceItem" class="content">
    <div class="top-c">
      <div class="top-title">价格走势</div>
      <div class="price-info">
        <div class="price-info-item">
          <div class="info-title"></div>
          <div class="info-item">
            {{ trendType == 1 ? "月度" : trendType == 2 ? "7日" : "30日" }}均价
          </div>
          <div class="info-item">
            {{ trendType == 1 ? "月度" : trendType == 2 ? "7日" : "30日" }}最低
          </div>
          <div class="info-item">
            {{ trendType == 1 ? "月度" : trendType == 2 ? "7日" : "30日" }}最高
          </div>
        </div>
        <div class="price-info-item">
          <div class="info-title">价格</div>
          <div class="info-item info-price">{{ averagePriceVO.avgPrice }}</div>
          <div class="info-item info-price">{{ averagePriceVO.minPrice }}</div>
          <div class="info-item info-price">{{ averagePriceVO.maxPrice }}</div>
        </div>
        <div class="price-info-item">
          <div class="info-title">日期</div>
          <div class="info-item date-price"></div>
          <div class="info-item date-price">{{ averagePriceVO.minTime }}</div>
          <div class="info-item date-price">{{ averagePriceVO.maxTime }}</div>
        </div>
        <div class="refresh-time">
          <div>更新时间 {{ update }}</div>
          <div>单位：元/吨</div>
        </div>
      </div>
      <div :id="typeId" class="echart-c" ref="chart"></div>
      <div class="date-time">
        <div
          @click="changeTime(1)"
          :class="{ 'item-un': trendType == 1, 'item-sel': trendType != 1 }"
        >
          月度走势
        </div>
        <div
          @click="changeTime(2)"
          :class="{ 'item-un': trendType == 2, 'item-sel': trendType != 2 }"
        >
          7日
        </div>
        <div
          @click="changeTime(3)"
          :class="{ 'item-un': trendType == 3, 'item-sel': trendType != 3 }"
        >
          30日
        </div>
      </div>
    </div>
    <div class="bottom-c">
      <div class="b-ttitle">
        <div class="b-title-item">
          <img class="b-title-item-img" src="@/assets/discover/p-sz.png" alt />
          <div class="b-title-num">{{ afloat.rise }}家</div>
          <img
            class="b-title-item-img-sz"
            src="@/assets/discover/price_sz.png"
            alt
          />
        </div>
        <div class="b-title-sx"></div>
        <div class="b-title-item">
          <img class="b-title-item-img" src="@/assets/discover/p_xd.png" alt />
          <div class="b-title-num">{{ afloat.drop }}家</div>
          <img
            class="b-title-item-img-sz"
            src="@/assets/discover/price_zd.png"
            alt
          />
        </div>
      </div>
      <div class="bottom-bar">
        <div class="bottom-bar-right">
          <div class="jgrb">价格日报</div>
          <img
            class="b-title-item-img-sz"
            src="@/assets/discover/price_sz.png"
            alt
          />
          <div class="b-title-num">涨</div>
          <img
            class="b-title-item-img-sz"
            src="@/assets/discover/price_zd.png"
            alt
          />
          <div class="b-title-num">跌</div>
        </div>
        <div @click="showPicker(1)" class="choosedate">
          <img class="rili" src="@/assets/discover/rili.png" alt />
          <div>{{ dateStr }}</div>
        </div>
      </div>
      <div class="bottom-list">
        <div class="title">
          <div class="t-name">地区</div>
          <div class="t-item">厂家</div>
          <div  @click="showPicker(2)" class="t-item">
            <span>价格</span>
            <span class="unit-price">{{unitStr}}</span>
            <img class="unit-img" src="@/assets/discover/unit.png">
          </div>
          <div class="t-item">涨跌</div>
        </div>

        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model:loading="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div class="list" v-for="(item, index) in list" :key="index">
              <div class="t-name">
                <div class="province">{{ item.city ?? "-市" }}</div>
                <div class="city">{{ item.province ?? "-省" }}</div>
              </div>
              <div class="t-item">{{ item.factory }}</div>
              <div class="t-item">{{ item.todayPrice }}</div>
              <div class="t-item">
                <img
                  v-if="item.floatState == 0"
                  class="b-title-item-img-sz"
                  src="@/assets/discover/price_zd.png"
                  alt
                />
                <img
                  v-if="item.floatState == 1"
                  class="b-title-item-img-sz"
                  src="@/assets/discover/price_sz.png"
                  alt
                />
                <div class="b-title-num">{{ item.floatPrice }}</div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
    <div class="pop-date">
      <van-popup
        get-container="#app"
        v-model:show="showBottom"
        position="bottom"
        :style="{ height: '30%' }"
      >
        <van-date-picker
        v-if="pickType==1"
          @cancel="cancel"
          @confirm="confirm"
          :min-date="minDate"
          :max-date="maxDate"
          v-model="currentDate"
          title="选择日期"
        />
        <van-picker
        v-if="pickType==2"
          title="请选择单位"
          :columns="columns"
          @confirm="confirm"
          @cancel="cancel"
        />
      </van-popup>
    </div>
  </div>
</template>

<script>
import { DatePicker, Popup, PullRefresh, List, Picker } from "vant";
import * as echarts from "echarts";
import { getShopPrice, getShopPriceList } from "@/api/request";
import { markRaw } from "vue";
export default {
  name: "LcvueShopPriceItem",

  data() {
    return {
      showBottom: false,
      trendType: 1,
      myChart: null,
      page: 0,
      size: 10,
      finished: false,
      loading: false,
      refreshing: false,
      dateStr: "",
      currentDate: [],
      minDate: new Date(1990, 0, 1),
      maxDate: new Date(),
      averagePriceVO: {},
      update: "",
      days: [],
      prices: [],
      afloat: {},
      list: [],
      min: 0,
      max: 0,
      columns: [
        { text: "吨", value: "0" },
        { text: "斤", value: "1" },
      ],
      pickType: 1,
      unit:'0',
      unitStr:'吨'
    };
  },
  props: ["typeId", "isShow"],

  components: {
    [DatePicker.name]: DatePicker,
    [Popup.name]: Popup,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Picker.name]: Picker,
  },

  mounted() {
    localStorage.setItem("token", this.$route.query.token);
    let timeOne = new Date();
    let year = timeOne.getFullYear();
    let month = timeOne.getMonth() + 1;
    let day = timeOne.getDate();
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;
    this.currentDate = [`${year}`, `${month}`, `${day}`];
    this.dateStr = `${year}-${month}-${day}`;
    this.getPrice();
  },

  methods: {
    onRefresh() {
      this.page = 1;
      this.list = [];
      this.getList();
    },
    onLoad() {
      this.page++;
      this.loading = true;
      this.getList();
    },
    async getPrice() {
      this.days = [];
      this.prices = [];
      let res = await getShopPrice(this.trendType, this.typeId);
      if (res.code == 200) {
        this.averagePriceVO = res.data["averagePriceVO"];
        this.update = res.data["update"];
        let arr = res.data["factoryAveragePrice"];

        let index = 0;
        arr.forEach((e) => {
          let timeOne = new Date(e.time);
          let month = timeOne.getMonth() + 1;
          let day = timeOne.getDate();
          month = month < 10 ? "0" + month : month;
          day = day < 10 ? "0" + day : day;
          let timeStr = month + "-" + day;
          this.days.push(timeStr);
          this.prices.push(e.averagePrice);
          if (index == 0) {
            this.min = e.averagePrice;
            this.max = e.averagePrice;
          }
          if (this.min > e.averagePrice) {
            this.min = e.averagePrice;
          }
          if (this.max < e.averagePrice) {
            this.max = e.averagePrice;
          }
          index++;
        });
        this.getEchartData();
      }
    },
    async getList() {
      let time = Date.parse(this.dateStr);
      let res = await getShopPriceList(this.page, this.size, time, this.typeId,this.unit);
      this.loading = false;
      this.refreshing = false;
      if (res.code == 200) {
        this.list = this.list.concat(res.data.list);
        this.afloat = res.data.afloat;
        if (res.data.list.length < this.size) {
          this.finished = true;
        } else {
          this.finished = false;
        }
      } else {
        this.finished = true;
      }
    },
    changeTime(type) {
      this.trendType = type;
      this.getPrice();
    },
    getEchartData() {
      this.min = Math.floor(this.min);
      this.max = Math.ceil(this.max);
      if (this.myChart == null) {
        var chartDom = document.getElementById(this.typeId);
        this.myChart = markRaw(echarts.init(chartDom));
      }
      if (this.myChart) {
        const option = {
          grid: {
            left: "3%",
            right: "6%",
            bottom: "0%",
            top: "10%",
            containLabel: true,
          },
          dataZoom: [
            {
              type: "slider",
              startValue: 0,
              endValue: 5,
            },
          ],
          color: ["#F1722D", "#2D61F1"],
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: this.days,
            axisLabel: {
              interval: 0,
            },
          },
          yAxis: {
            type: "value",
            min: this.min,
            max: this.max,
          },
          series: [
            {
              type: "line",
              smooth: true,
              data: this.prices,
            },
          ],
        };
        this.myChart.setOption(option);
        setTimeout(function () {
          window.onresize = function () {
            this.myChart.resize();
          };
        }, 200);
      }
    },
    cancel() {
      this.showBottom = false;
    },
    confirm(res) {
      this.showBottom = false;
      if(this.pickType==1){
      this.dateStr = `${res.selectedValues[0]}-${res.selectedValues[1]}-${res.selectedValues[2]}`;
      this.onRefresh();
      }else if(this.pickType==2){
        console.log(res)
        this.unit = res.selectedValues[0]
        if(this.unit == '0'){
          this.unitStr = '吨'
        }else{
          this.unitStr =  '斤'
        }
        
        this.onRefresh();
      }
      
    },
    showPicker(type) {
      this.pickType = type;
      this.showBottom = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f4f4;
}
.top-c {
  padding: 15px;
  margin: 10px;
  width: 334px;
  background: #ffffff;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  .top-title {
    width: 86px;
    height: 23px;
    font-size: 20px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: 700;
    color: #000000;
  }
  .price-info {
    margin-top: 20px;
    padding: 15px 10px;
    background: #f9f9f9;
    border-radius: 11px 11px 11px 11px;
    opacity: 1;
    border: 1px solid rgba(0, 0, 0, 0.13);
    .price-info-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 40px;
      font-size: 12px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #000000;
      .info-title {
        flex: 1;
        font-size: 12px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #000000;
      }
      .info-item {
        flex: 2;
      }
      .date-price {
        font-size: 12px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #000000;
      }
      .info-price {
        font-size: 12px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: 700;
        color: #ff1818;
      }
    }
    .refresh-time {
      border-top: 1px solid rgba(0, 0, 0, 0.13);
      padding: 0 10px;
      padding-top: 15px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #000000;
    }
  }
  .echart-c {
    width: 100%;
    height: 250px;
  }

  .date-time {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.bottom-c {
  padding: 15px;
  margin: 10px;
  width: 334px;
  background-color: white;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  .b-ttitle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .b-title-item {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex: 1;
      .b-title-item-img {
        width: 40px;
        margin-right: 5px;
      }
    }
    .b-title-sx {
      width: 0px;
      height: 30px;
      opacity: 1;
      border: 1px solid #d8d8d8;
    }
  }
  .bottom-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    .bottom-bar-right {
      display: flex;
      flex: 1;
      justify-content: flex-start;
      align-items: center;
      .jgrb {
        margin-right: 10px;
        width: 66px;
        height: 20px;
        font-size: 15px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;

        color: #1a051d;
      }
    }
    .choosedate {
      margin-top: 20px;
      margin-bottom: 20px;
      width: 150px;
      height: 40px;
      background: #f4f5f8;
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #51504f;
      .rili {
        width: 25px;
        margin-right: 10px;
      }
    }
  }
  .bottom-list {
    width: 100%;
    .title {
      width: 100%;
      height: 53px;
      opacity: 1;
      border-bottom: 1px solid #dddddd;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: rgba(26, 5, 29, 0.6682);
      .t-name {
        flex: 1;
      }
      .t-item {
        flex: 1;
        .unit-price{
          margin-left: 10px;
          color: #E45D11;
          margin-right: 5px;
        }
        .unit-img{
          margin-top: 5px;
          width: 10px;
        }
      }
    }
    .list {
      width: 100%;
      min-height: 53px;
      border-bottom: 1px solid #dddddd;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #1a051d;
      .t-name {
        flex: 1;
        .province {
          font-size: 13px;
          padding: 0 6px;
        }
        .city {
          font-size: 10px;
          color: rgba(26, 5, 29, 0.6682);
        }
      }
      .t-item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
      }
    }
  }
}
.b-title-item-img-sz {
  height: 20px;
}
.b-title-num {
  margin-right: 5px;
  font-size: 15px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #1a051d;
}
.item-un {
  margin: 0 10px;
  width: 89px;
  height: 30px;
  background: #ff7a3d;
  border-radius: 116px 116px 116px 116px;
  opacity: 1;
  border: 1px solid #f65e19;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: 700;
  color: #ffffff;
}
.item-sel {
  margin: 0 10px;
  width: 89px;
  height: 30px;
  border-radius: 116px 116px 116px 116px;
  opacity: 1;
  border: 1px solid #f65e19;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: 700;
  color: #ff7a3d;
}
/deep/.van-list {
  width: 100%;
}
</style>