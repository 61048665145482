import request from "@/api/index.js";

export function getZixunDetail(zid) {
  return new Promise(resove => {
    request({
      url: `/article/sharedetail/` + zid,
      method: "get",
    }).then(res => {
      resove(res.data);
    });
  });
}
