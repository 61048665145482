import axios from 'axios';
import { showToast } from 'vant';

const request = axios.create({
  baseURL: 'https://app-dev.liangce.cc/api', //https://app.liangce.cc/api 完整的api地址 = 接口域名+ 接口路径 http://192.168.16.92:8085/api
  timeout: 7200000 // 请求超时时间 5s
})

// 跨域请求，允许保存cookie
request.defaults.withCredentials = true

// HTTPrequest拦截
request.interceptors.request.use(config => {
  const token = localStorage.getItem("token");
  let user_id = localStorage.getItem("userId");

  if(token!=null&&token!=undefined&&token!=''){
    config.headers.Authorization = token
  }
  config.headers.userId = user_id 
  config.headers.appid = 'lc-app'
  config.headers.clientVersion = '1.0.0'
  config.headers.clientType = 'lcvue'

  console.log('请求url===============',config.url)
  console.log('请求参数===============',config.data)
  return config
}, error => {
  return Promise.reject(error)
})

// HTTPresponse拦截
request.interceptors.response.use(res => {
  let message = res.data.message 

  console.log('返回结果===============',res.data)

  if (res.data.code == 503) {
    showToast({
      message: 'Token不存在或已过期',
      position: 'top',
    })
    return res
  }else if(res.data.code != 200&&res.data.code!=0&&message!=undefined&&message.length!=0){
    showToast({
      message: message,
      position: 'top',
    })
  }
  
  return res
}, error => {
  return Promise.reject(error);
})

export default request
