<template>
  <div class="content">
    <div class="top-bar">
      <img class="logo" src="@/assets/icon.png" alt="icon" />
      <div class="info">打开粮策APP，了解市场行情</div>
      <div v-if="isWX">
        <wx-open-launch-app
          id="launch-btn"
          appid="wx66c7689c38662fd9"
          :extinfo="extinfo"
        >
          <component :is="'script'" type="text/wxtag-template">
            <div
              style="
                width: 99px;
                height: 55px;
                background: #ff8021;
                color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
              "
              class="btn"
            >
              立即打开
            </div>
          </component>
        </wx-open-launch-app>
      </div>
      <div v-else @click="openApp" class="open-btn">立即打开</div>
    </div>
    <div class="xh-info-top">
      <div v-if="xianhuoInfo.images" class="xh-top">
        <van-swipe>
          <van-swipe-item
            v-for="(item, index) in xianhuoInfo.images"
            v-bind:key="index"
            class="top-item"
          >
            <img class="s-img" :src="item" alt="" />
          </van-swipe-item>
          <template #indicator="{ active, total }">
            <div class="custom-indicator">{{ active + 1 }}/{{ total }}</div>
          </template>
        </van-swipe>
      </div>
      <div v-else class="xh-info-top-noimg"></div>
      <div class="xh-price">
        <div class="price">
          ¥{{ xianhuoInfo.price
          }}<span class="dw"
            >/{{ xianhuoInfo.productUnit == 1 ? "斤" : "吨" }}</span
          >
        </div>
        <div v-if="xianhuoInfo.bargain == 1" class="yj">可议价</div>
        <div v-if="xianhuoInfo.publishType == 1" class="cg">采购</div>
        <div class="num">数量：{{ xianhuoInfo.numbers }}吨</div>
      </div>
      <div class="xh-name">
        <span class="xh-year">{{ xianhuoInfo.year }}年</span
        >{{ xianhuoInfo.name }}
      </div>
      <div class="xh-address">
        <div class="hx"></div>
        <div v-if="address" class="address-item">
          <div class="item-name">经营地址:</div>
          <div class="item-value">{{ address }}</div>
        </div>
        <div class="address-item">
          <div class="item-name">产地:</div>
          <div class="item-value">{{ xianhuoInfo.production }}</div>
        </div>
        <div class="time">更新时间：{{ xianhuoInfo.updateTime }}</div>
      </div>
    </div>
    <div class="xh-detail">
      <div class="detail-user">
        <img class="user-head" :src="xianhuoInfo.user.img" alt="" />
        <div class="user-info">
          <div class="user-top">
            <div class="user-name">{{ xianhuoInfo.user.name }}</div>
            <img
              v-if="xianhuoInfo.user.card == 1"
              class="sm-img"
              src="@/assets/xianhuo/min_sm.png"
              alt=""
            />
            <img
              v-if="xianhuoInfo.user.vip == 1"
              class="sm-img"
              src="@/assets/xianhuo/min_vip.png"
              alt=""
            />
          </div>
          <div class="user-sm">
            <img
              v-if="xianhuoInfo.user.card == 1"
              class="sm-img"
              src="@/assets/xianhuo/smrz_succ.png"
              alt=""
            />
            <div class="sm-name">{{ xianhuoInfo.user.realName }}</div>
          </div>
        </div>
        <div class="user-right">
          <div @click="openApp" class="right-look">查看商家</div>
          <div class="num">已发布{{ xianhuoInfo.user.number }}条信息</div>
        </div>
      </div>
      <div class="xh-detail-info">
        <div class="tj-video">
          <div class="tj-hx"></div>
          <div class="tj-text">详情描述</div>
          <div class="tj-hx"></div>
        </div>
        <div class="xh-desc">
          <span class="desc-name">详情描述:</span>{{ xianhuoInfo.description }}
        </div>
        <div class="img-list">
          <img
            v-for="(item, index) in xianhuoInfo.images"
            :key="index"
            :src="item"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="xianhuo">
      <div class="xianhuo-top">
        <div class="top-left"></div>
        <div class="top-title">为您推荐</div>
      </div>
      <ul class="xianhuo-list">
        <li
          v-for="(item, index) in list"
          v-bind:key="index"
          class="xianhuo-item"
          @click="openApp"
        >
          <div class="xianhuo-img">
            <img :src="item.images[0]" alt="" />
          </div>
          <div class="xianhuo-name">{{item.name}}</div>
          <div class="xianhuo-price-num">
            <div class="price">¥{{item.price}}<span class="dw">{{item.productUnit==2?'/吨':'/斤'}}</span></div>
          </div>
          <div class="xianhuo-hx"></div>
          <div class="xianhuo-address-year">
            <img src="@/assets/xianhuo/xh_time_year.png" alt="">
            <div class="address">
              {{item.updateTime}}
            </div>
            <div class="year">{{item.numbers}}吨</div>
          </div>
          <div v-if="item.address" class="xianhuo-address-year">
            <img src="@/assets/xianhuo/fabu_dt.png" alt="">
            <div class="address">
              
          {{getAddress(item.address)}}
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div @click="openApp" class="bottom">
      打开粮策APP联系商家，发布信息<span class="free">都免费</span>
    </div>
  </div>
</template>

<script>
import { Swipe, SwipeItem } from "vant";
import { getXianHuoDetail, getXianHuoHotList } from "@/api/xianhuo/xianhuo";
import { wechatSign } from "@/api/wechat/wechat"
export default {
  data() {
    return {
      xianhuoInfo: {
        user: {},
        images:[]
      },
      list: [],
      shareId:'',
        shareTitle:'粮策-买卖存粮有策略',
        shareDesc:'粮食生意不好做，看不透市场，就来粮策！为您的粮食生意保驾护航！',
        isWX:false,
        extinfo:'',
        address:''
    };
  },
  mounted() {
    var ua = navigator.userAgent.toLowerCase(); //判断是否是微信浏览器
    this.isWX = ua.indexOf("micromessenger") != -1; //判断是否     
    
    this.shareId = this.$route.query.shareId
    if(this.isWX){
      this.wxSign()
    }
    this.getDetail();
    this.getxhList();
  },
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  methods: {
    getAddress(ad){
      if(ad!=null&&ad!=undefined){
          let arr = ad.split(' ')
          if(arr.length>=3){
            return arr[0] + ' ' + arr[1] + ' '+arr[2]
          }else{
           return  ad
          }
        }
    },
     wxSign(){
      let info = {
        'title':this.shareTitle,
        'desc':this.shareDesc,
        'link':window.location.href,
      }
      wechatSign(window.location.href.split("#")[0],info)
    },
    openApp() {
      window.location.href =
        "https://we.xyzzzzz.com";
    },
    async getDetail() {
      let res = await getXianHuoDetail(this.shareId);
      if (res.code == 200) {
        this.xianhuoInfo = res.data;
        this.extinfo = 'lcapp://app?shareType=2&shareId=' + this.shareId;
        if(this.xianhuoInfo.address!=null&&this.xianhuoInfo.address!=undefined){
          let arr = this.xianhuoInfo.address.split(' ')
          if(arr.length>=3){
            this.address = arr[0] + ' ' + arr[1] + ' '+arr[2]
          }else{
            this.address = this.xianhuoInfo.address
          }
        }
      }
    },
    async getxhList() {
      let res = await getXianHuoHotList();
      if (res.code == 200) {
        this.list = res.data;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  background-color: #efefef;
  position: relative;
  padding-bottom: 80px;
  .top-bar {
    background-color: white;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 55px;
    background: #333333;
    font-size: 15px;
    font-weight: 500;
    color: #ffffff;
    line-height: 20px;
    .logo {
      width: 40px;
      height: 40px;
      margin: 0 17px;
    }
    .info {
      flex: 1;
      text-align: left;
    }

    .open-btn {
      width: 99px;
      height: 100%;
      background: #ff8021;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .xh-info-top-noimg {
    padding-top: 60px;
  }
  .xh-info-top {
    background-color: white;
    .xh-top {
      padding-top: 55px;
      height: 200px;
      width: 100%;
      background-color: white;
      .top-item {
        height: 200px;
        background-color: white;
        img {
          width: 100%;
        }
      }
    }
    .xh-price {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 40px;
      .price {
        font-size: 28px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #ff1818;
      }
      .dw {
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #000000;
      }
      .yj {
        margin-left: 10px;
        width: 62px;
        height: 20px;
        background: #ffede3;
        opacity: 1;
        border: 1px solid #ffb28b;
        font-size: 14px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #eb7a38;
      }
      .cg {
        margin-left: 10px;
        width: 48px;
        height: 20px;
        background: #ffede3;
        opacity: 1;
        border: 1px solid #ffb28b;
        font-size: 14px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #eb7a38;
      }
      .num {
        text-align: right;
        flex: 1;
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #000000;
      }
    }
    .xh-name {
      margin-top: 5px;
      padding-left: 20px;
      padding-right: 20px;
      text-align: left;
      font-size: 18px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #1a051d;
      .xh-year {
        margin-right: 5px;
        padding-left: 5px;
        padding-right: 5px;
        height: 20px;
        background: #eb6e30;
        border-radius: 10px 10px 10px 10px;
        opacity: 1;
        border: 1px solid #ea6b2b;
        font-size: 12px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: 700;
        color: #ffffff;
      }
    }
    .xh-address {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 10px;
      .hx {
        margin-top: 10px;
        margin-bottom: 10px;
        width: 376px;
        height: 1px;
        background: #e9e8e9;
        opacity: 1;
      }
      .address-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 5px;
        .item-name {
          width: 60px;
          font-size: 12px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #000000;
          text-align: left;
        }
        .item-value {
          flex: 1;
          font-size: 12px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          text-align: left;
        }
      }
      .time {
        margin-top: 10px;
        font-size: 12px;
        font-family: Source Han Sans CN-Light, Source Han Sans CN;
        font-weight: 300;
        color: #000000;
      }
    }
  }
  .xh-detail {
    margin-top: 10px;
    padding: 20px;
    background-color: white;
    .detail-user {
      display: flex;
      justify-content: center;
      align-items: center;
      .user-head {
        width: 51px;
        height: 51px;
      }
      .user-info {
        margin-left: 10px;
        margin-right: 10px;
        flex: 1;
        .sm-img {
          height: 14px;
        }
        .user-top {
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
        .user-sm {
          margin-top: 5px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
      }
      .user-right {
        .right-look {
          font-size: 14px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #eb6e30;
        }
        .num {
          margin-top: 5px;
          font-size: 10px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #1a051d;
        }
      }
    }
    .xh-detail-info {
      .tj-video {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        .tj-hx {
          width: 48px;
          height: 2px;
          background: #999999;
          border-radius: 0px 0px 0px 0px;
        }
        .tj-text {
          font-size: 20px;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          color: #333333;
          margin-left: 20px;
          margin-right: 20px;
        }
      }
      .xh-desc {
        text-align: left;
        font-size: 15px;
        .desc-name {
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #1a051d;
          margin-right: 5px;
        }
        color: #666666;
      }
      .img-list {
        margin-top: 20px;
        img {
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }
  }
  .xianhuo {
    padding-left: 20px;
    padding-right: 20px;
    .xianhuo-top {
      margin-top: 16px;
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      .top-left {
        width: 7px;
        height: 26px;
        background: #ff7916;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
      }
      .top-title {
        margin-left: 10px;
        font-size: 20px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #000000;
      }
    }
    .xianhuo-list {
      //   display: flex;
      //   flex-flow: row wrap;
      //   justify-content: space-between;
      box-sizing: border-box;
      // background-color: #fff;
      column-count: 2;
      column-gap: 20px;
      // padding: 20px;
      .xianhuo-item {
        // height: auto;
        margin-bottom: 12px;
        width: 182px;
        border-radius: 5px 5px 0px 0px;
        background-color: white;
        padding-bottom: 10px;
        .xianhuo-img {
          width: 182px;
          height: 164px;
          background: #d8d8d8;
          border-radius: 5px 5px 0px 0px;
          opacity: 1;
          img{
            width: 182px;
           height: 164px;
          }
        }
        .xianhuo-name {
          padding-left: 12px;
          padding-right: 12px;
          margin-top: 10px;
          margin-bottom: 6px;
          font-size: 12px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #000000;
          text-align: left;
        }
        .xianhuo-price-num {
          padding-left: 12px;
          padding-right: 12px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .price {
            font-size: 15px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #ff1818;
            .dw {
              font-size: 10px;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: #000000;
            }
          }
          .num {
            font-size: 10px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #000000;
          }
        }
        .xianhuo-hx {
          margin-left: 8px;
          margin-top: 10px;
          margin-bottom: 10px;
          width: 170px;
          height: 1px;
          background: #e9e8e9;
          opacity: 1;
        }
        .xianhuo-address-year {
          padding-left: 12px;
          padding-right: 12px;
          border-radius: 5px 5px 0px 0px;
          display: flex;
          font-size: 10px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #000000;
          align-items: center;
          justify-content: center;
          margin-top: 10px;
          img{
              height: 15px;
            }
          .address {
            margin-left: 10px;
            flex: 1;
            text-align: left;
          }
        }
      }
    }
  }

  .bottom {
    position: fixed;
    left: 22px;
    right: 22px;
    bottom: 20px;
    width: 370px;
    height: 51px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(288deg, #e46f34 0%, #ffa357 100%);
    border-radius: 7px 7px 7px 7px;
    opacity: 1;
    font-size: 14px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
    .free {
      font-size: 18px;
    }
  }
  .custom-indicator {
    position: absolute;
    right: 5px;
    bottom: 5px;
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    width: 42px;
    height: 24px;
    line-height: 24px;
    background: rgba(0, 0, 0, 0.4536);
    border-radius: 12px 12px 12px 12px;
    opacity: 1;
  }
}
</style>